(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/bought-fractions-text/assets/javascripts/bought-fractions-text.js') >= 0) return;  svs.modules.push('/components/lb-ui/bought-fractions-text/assets/javascripts/bought-fractions-text.js');

'use strict';

const {
  ReactIcon: Icon
} = svs.ui;
const BoughtFractionsText = _ref => {
  let {
    currentPurchasedFractions,
    maxFractionsPerMember,
    isPastTime,
    isSubmitted
  } = _ref;
  const hasFractions = currentPurchasedFractions > 0;
  const baseClass = 'f-600';
  const hadOrHave = isPastTime ? 'hade' : 'har';
  let maxFractionsText = '';
  if (!isSubmitted && maxFractionsPerMember > currentPurchasedFractions || isSubmitted && currentPurchasedFractions > 0 && maxFractionsPerMember !== currentPurchasedFractions) {
    maxFractionsText = React.createElement("span", {
      className: "".concat(baseClass, " fc-grey-400"),
      "data-testid": "bought-fractions-max-fractions"
    }, "(max", ' ', maxFractionsPerMember, ")");
  }
  let text = React.createElement("span", {
    className: "".concat(baseClass, " fc-grey-400"),
    "data-testid": "bought-fractions-text"
  }, "Du", ' ', hadOrHave, ' ', "ingen andel");
  if (isSubmitted && currentPurchasedFractions < 1) {
    text = React.createElement("span", {
      className: "".concat(baseClass, " fc-grey-400"),
      "data-testid": "bought-fractions-text"
    }, "Du deltog inte");
  }
  if (hasFractions) {
    text = React.createElement("span", {
      className: "".concat(baseClass, " fc-green f-medium"),
      "data-testid": "bought-fractions-text"
    }, React.createElement(Icon, {
      class: "margin-right margin-bottom-half",
      color: "fc-green",
      "data-testid": "bought-fractions-icon",
      icon: "checkmark",
      size: "300"
    }), "Du ".concat(hadOrHave, " ").concat(currentPurchasedFractions, " andel").concat(currentPurchasedFractions > 1 ? 'ar' : ''));
  }
  if (maxFractionsPerMember === undefined || maxFractionsPerMember <= 1) {
    return text;
  }
  return React.createElement("div", {
    className: "inline-block"
  }, text, ' ', maxFractionsText);
};
BoughtFractionsText.defaultProps = {
  currentPurchasedFractions: 0,
  isPastTime: false
};
setGlobal('svs.components.lbUi.BoughtFractionsText', BoughtFractionsText);

 })(window);